import { Alert } from '@mui/material';
import { setDecimalPlaces, tolocalTime } from '../utils/helper';
import GlobalModal from './Global/GlobalModal';
import StepLine from './StepLine';
import StepNumber from './StepNumber';
import { danger } from '../images/others';
import StatusChip from './StatusChip';
import { Link } from 'react-router-dom';
import {
  formatNumberWithCommas,
  toFixedWithoutRoundingOff,
} from '../utils/utils';

type GlobalStepperProps = {
  step: number;
  enableBackground: any;
  lineEnable?: boolean;
};

const GlobalStepper = ({
  step,
  enableBackground = null,
  lineEnable = true,
}: GlobalStepperProps) => {
  return (
    <div className="h-full transition-all">
      <StepNumber
        number={step}
        enableBackground={enableBackground}
        active={true}
      />
      {lineEnable && <StepLine enableBackground={enableBackground} />}
    </div>
  );
};
interface IHistoryHeadings {
  dataClass: string;
  id: string;
  label: string;
}

interface HistoryPopupProps {
  onClose: () => void;
  open?: boolean;
  headings: Array<IHistoryHeadings>;
  data: any;
  fromComponent?: string;
  type?: string;
}

export default function HistoryPopup({
  onClose,
  open = false,
  headings,
  data,
  fromComponent,
  type,
}: HistoryPopupProps) {
  const Type = (() => {
    switch (type) {
      case 'deposit-and-stake-request':
        return 'Deposit';
      case 'deposit-and-stake':
        return 'deposit-and-stake';
      default:
        return type;
    }
  })();
  return (
    <GlobalModal size="xl" onClose={onClose} isOpen={open} title=" ">
      <div className="flex flex-col mt-2">
        <div className="flex flex-col w-full h-full md:flex-row">
          {fromComponent?.toLowerCase() !== 'deposit' ? (
            <>
              <div className="flex-1 my-2">
                <div className="flex w-full py-2">
                  <div className="w-14">
                    <GlobalStepper
                      step={1}
                      enableBackground={true}
                      lineEnable={true}
                    />
                  </div>
                  <div className="flex flex-col items-start w-full gap-2">
                    <div className="text-sm font-semibold text-defaultBlack">
                      {fromComponent?.toLowerCase() ===
                      'deposit-and-stake-request'
                        ? 'Request Created'
                        : `${type} Order Submitted`}
                    </div>
                    <div className="pt-2 pb-4 text-sm">
                      {data['time'] ? tolocalTime(data['time']) : '-'}
                    </div>
                  </div>
                </div>

                {fromComponent?.toLowerCase() === 'deposit-and-stake-request' ||
                fromComponent?.toLowerCase() === 'deposit-and-stake' ? (
                  <div className="flex w-full py-4">
                    <div className="w-14">
                      <GlobalStepper
                        step={2}
                        enableBackground={
                          data.status === 'success' ||
                          data.status === 'completed'
                            ? true
                            : false
                        }
                        lineEnable={false}
                      />
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <div className="text-sm font-semibold text-defaultBlack">
                        <StatusChip status={data.status} />
                      </div>
                      <span className="pt-2 pb-4 text-sm">
                        {data.status === 'expired' ||
                        data.status === 'cancelled'
                          ? tolocalTime(data['expiredtime'])
                          : tolocalTime(data['updatetime'])}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="flex w-full py-4">
                    <div className="w-14">
                      <GlobalStepper
                        step={2}
                        enableBackground={true}
                        lineEnable={true}
                      />
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <div className="text-sm font-semibold text-defaultBlack">
                        System Processing
                      </div>
                      <div className="pt-2 pb-4 text-sm">
                        {data['time'] ? tolocalTime(data['time']) : '-'}
                      </div>
                    </div>
                  </div>
                )}

                {(fromComponent?.toLowerCase() ===
                  'deposit-and-stake-request' ||
                  fromComponent?.toLowerCase() === 'deposit-and-stake') &&
                (data.status !== 'success' || data.status !== 'completed') ? (
                  <></>
                ) : (
                  <div className="flex w-full py-2">
                    <div className="w-14">
                      <GlobalStepper
                        step={3}
                        enableBackground={true}
                        lineEnable={false}
                      />
                    </div>
                    <div className="flex flex-col w-full gap-2">
                      <div className="text-sm font-semibold text-defaultBlack">
                        Completed
                      </div>
                      <div className="text-sm">
                        {data['updatetime']
                          ? tolocalTime(data['updatetime'])
                          : data['time']
                            ? tolocalTime(data['time'])
                            : '-'}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            ''
          )}

          <div className="w-full my-2 md:flex-1">
            <div className="w-full">
              {headings
                .filter((cell) =>
                  fromComponent?.toLowerCase() !== 'deposit'
                    ? cell.id !== 'time' && cell.id !== 'requestNumber'
                    : cell.id !== 'requestNumber'
                )
                .map((item: any) => (
                  <div className="flex flex-row w-full gap-2 py-2 md:flex-col">
                    <div className="text-sm font-semibold text-defaultBlack">
                      {item.label}
                    </div>
                    <div className="text-sm whitespace-normal overflow-wrap-anywhere">
                      {['previousBalance', 'newBalance', 'amount'].includes(
                        item.id
                      ) ? (
                        formatNumberWithCommas(
                          setDecimalPlaces(Number(data[item.id]), 6)
                        )
                      ) : item.label?.toLowerCase() === 'status' ? (
                        <StatusChip status={data[item.id]} />
                      ) : item.id === 'time' ? (
                        tolocalTime(data[item.id])
                      ) : item.id === 'hash' && Array.isArray(data[item.id]) ? (
                        <ul>
                          {data[item.id].map((item: any) => (
                            <li>• {item}</li>
                          ))}
                        </ul>
                      ) : data[item.id] ? (
                        typeof data[item.id] === 'string' ||
                        Array.isArray(data[item.id]) ? (
                          data[item.id].length < 20 ? (
                            data[item.id]
                          ) : (
                            data[item.id]
                          )
                        ) : (
                          JSON.stringify(data[item.id])
                        )
                      ) : item.id === 'bonus' && !data[item.id] ? (
                        0
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="flex w-full">
          <Alert
            severity="info"
            icon={<img src={danger} alt="danger" />}
            className="!w-full"
            sx={{
              '& .MuiAlert-message': {
                width: 'auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
            }}
          >
            <div className="py-2 text-primary">
              <div className="text-xs ">
                Please note that you will receive an email once it is completed.
              </div>
              <div className="text-xs font-medium">
                Why hasn’t my {Type} arrival?
                <Link to="/support"> Contact Support.</Link>
              </div>
            </div>
          </Alert>
        </div>
      </div>
    </GlobalModal>
  );
}
