/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Skeleton } from '@mui/material';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthOTP from '../../components/AuthOtp';
import CardLayout from '../../components/Cards/CardLayout';
import EmptyPage from '../../components/EmptyPage';
import GlobalButton from '../../components/Global/GlobalButton';
import GlobalHeading from '../../components/Global/GlobalHeading';
import GlobalSubHeading from '../../components/Global/GlobalSubheading';
import GlobalSuccess from '../../components/Global/GlobalSuccess';
import GlobalTable from '../../components/Global/GlobalTable';
import HistoryPopup from '../../components/HistoryPopup';
import StatusChip from '../../components/StatusChip';
import NoData from '../../components/Table/NoData';
import useUserCheck from '../../hooks/useUserCheck';
import { danger } from '../../images/others';
import { back } from '../../images/wallets';
import { useWalletDataAction } from '../../store/walletData/walletDataAction';
import { selectWalletData } from '../../store/walletData/walletDataSlice';
import { depositSliceAction } from '../../store/wallets/depositSlice';
import { useWalletActions } from '../../store/wallets/walletsAction';
import { selectWallet } from '../../store/wallets/walletsSlice';
import { errorAlert } from '../../utils/alerts';
import { TOKEN_ROUND_ICONS } from '../../utils/allConstants';
import { otpValidaty } from '../../utils/utils';
import DepositForm from './_components/deposit/DepositForm';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import CopyItemsView from '../../components/Global/CopyItemsView';
import GlobalSelect from '../../components/Global/GlobalSelect';
import { PLATFORM_NAME } from '../../utils/constants';
import HoverBorderAnimation from '../../components/HoverBorderAnimation';
import { tolocalTime } from '../../utils/helper';
import FAQAccordian from './_components/FAQAccordian';
import { IHeadCells } from '../../types/superNodes.type.ts';
import { selectCloudSlice } from '../../store/Cloudk2/clouldk2Slice.ts';
import { useCloudActions } from '../../store/Cloudk2/cloudk2Actions.ts';
import { ReactComponent as ArrowRight } from '../../SVGIcons/arrow-right.svg';

type Address = {
  address: string;
  network: string;
};

const headCells: IHeadCells[] = [
  {
    id: 'id',
    label: '# ID',
    dataClass: 'w-[160px]',
  },
  {
    id: 'time',
    label: 'Time',
    dataClass: 'w-[160px]',
  },
  {
    id: 'type',
    label: 'Type',
    dataClass: 'w-[160px] break-words',
  },
  {
    id: 'wallet',
    label: 'Deposit Wallet',
    dataClass: 'w-[120px]',
  },
  {
    id: 'coin',
    label: 'Coin',
    dataClass: 'w-[120px]',
  },
  {
    id: 'previousBalance',
    label: 'Previous Balance',
    dataClass: 'w-[120px]',
  },
  {
    id: 'newBalance',
    label: 'New Balance',
    dataClass: 'w-[120px]',
  },
  {
    id: 'amount',
    label: 'Amount',
    dataClass: 'w-[90px]',
  },
  {
    id: 'destination',
    label: 'Destination',
    dataClass: 'w-[160px]',
  },
  {
    id: 'hash',
    label: 'TxID',
    dataClass: 'w-[160px]',
  },
  {
    id: 'status',
    label: 'Status',
    dataClass: 'w-[120px]',
  },
  {
    id: 'remarks',
    label: 'Remark',
    dataClass: 'w-[160px]',
  },
];

export default function WalletsDeposit(): ReactElement {
  const { user } = useUserCheck();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { machineList, userMachinesLoading } = useSelector(selectCloudSlice);
  const {
    getWithdrawHistory,
    getAllTokens,
    getAllNetworks,
    postWithdraw,
    walletAssets,
    getWithdrawAddresses,
  } = useWalletActions();

  const { getWalletDataDepositFaqs, getWalletDataDepositHistory } =
    useWalletDataAction();

  const { allNetworks, assets, allTokens } = useSelector(selectWallet);
  const {
    depositHistory,
    getWalletDataDepositHistoryLoading,
    depositFaqs,
    depositFaqsLoading,
    allTokensData,
    allTokensLoading,
  } = useSelector(selectWalletData);
  const [resetDisable, setResetDisable] = useState(
    !depositHistory?.list?.length
  );
  const { getAllTokens: getAllTokenData } = useWalletDataAction();
  const { getUserMachinesList } = useCloudActions();
  const [dataChanged, setDateChanged] = useState(false);

  const [filters, setFilters] = useState<any>({
    limit: 10,
    page: 1,
    timeperiod: 'alltime',
    type: 'all',
    fromDate: null,
    toDate: null,
    token: null,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDeposited, setIsDeposited] = React.useState(false);
  const [showHistory, setShowHistory] = React.useState(true);
  const [selectedNetwork, setSelectedNetwork] = React.useState('');
  const [searchValue, setSearchValue] = React.useState<any>(null);
  const [resetFilter, setResetFilter] = useState(false);
  const [selectedWallet, setSelectedWallet] = React.useState<any>({
    name: 'LYK - D',
    value: 'LYK - D',
    icon: TOKEN_ROUND_ICONS['USDT'],
  });

  const [amount, setAmount] = React.useState<string>('');
  const [isVerifying, setIsVerifying] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [vCode, setVCode] = React.useState<Array<string>>(
    Array.from({ length: 6 }, (): string => '')
  );
  const [selectedStatus, setSelectedStatus] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openIndex, setOpenIndex] = useState<number>(0);
  const [otpOpen, setOtpOpen] = React.useState<boolean>(false);
  const [otpMode, setOtpMode] = React.useState<string>('');
  const [successDialog, setSuccessDialog] = React.useState<boolean>(false);
  const [selectedUSDT, setSelectedUSDT] = React.useState<Address | any>('');
  const [validateErrorAmount, setValidateErrorAmount] =
    React.useState<any>(null);

  const filterEmptyCheck = (): boolean =>
    !searchValue && !dataChanged && !selectedStatus && !filters.token;

  const handleSubmit = async (): Promise<void> => {
    if (validateErrorAmount) return;
    setIsVerifying(true);

    if (selectedWallet && selectedUSDT && amount && selectedNetwork) {
      const data = {
        fromWallet: selectedWallet,
        toAddress: selectedUSDT,
        amount: Number(amount),
        network: selectedNetwork,
        otp: vCode.join('') ? vCode.join('') : null,
      };

      const response = await postWithdraw(data);

      if (response?.data?.status) {
        setErrorMessage('');
        if (response?.data?.data?.status === 'OTP_SENT') {
          setIsVerifying(false);
          setOtpOpen(true);
          setSuccessDialog(false);
          setOtpMode(response?.data?.data?.mode);
        } else {
          setIsVerifying(false);
          setIsVerifying(true);
          setOtpOpen(false);
          setSuccessDialog(true);
          await fetchHistory();
        }
      } else {
        setIsVerifying(false);
        setErrorMessage(response?.data?.message);
        // fire alert
        errorAlert(response?.data?.message);
      }
    }
    fetchHistory();
  };

  const selectedWalletToken = assets?.find(
    (item: any): boolean => item?._id === selectedWallet
  )?.token;
  const tokenSettings = allTokens?.find(
    (token: any): boolean => token?.symbol === selectedWalletToken?.symbol
  )?.settings;
  const withdrawSettings = tokenSettings?.find(
    (setting: any): boolean => setting.transactionType === 'WITHDRAW'
  );

  const updateValueDeposit = (value: any) =>
    dispatch(
      depositSliceAction.setDepositSlice({
        ...value,
      })
    );
  const handleResetDeposit = (): void => {
    updateValueDeposit({ selectedCoin: null });
    updateValueDeposit({ selectedNetwork: '' });
    updateValueDeposit({ currentStep: 1 });
  };

  const handleDateDate = (startDate: any, endDate: any): void => {
    const from: string = moment(startDate).format('YYYY-MM-DD');
    const to: string = moment(endDate).format('YYYY-MM-DD');

    // setDate({ fromDate: from, toDate: to });
    setFilters((prev: any) => ({
      ...prev,
      fromDate: from,
      toDate: to,
    }));
  };

  const fetchHistory = async (): Promise<void> => {
    setIsLoading(true);
    const response = await getWithdrawHistory({ page: 1 }, false);
    await walletAssets();
    if (!allTokens) await getAllTokens();
    if (!allNetworks) await getAllNetworks();
    await getWithdrawAddresses();
    setIsLoading(false);

    if (response?.data?.objects?.length < 1) {
      setShowHistory(false);
    }
  };

  useEffect((): void => {
    const isOTPNotValid = otpValidaty(vCode);
    if (!isOTPNotValid) {
      return setErrorMessage('');
    }
  }, [vCode]);

  useEffect((): void => {
    if (!depositFaqsLoading && depositFaqs === null) {
      getWalletDataDepositFaqs();
    }

    if (!userMachinesLoading && machineList === null) {
      getUserMachinesList(false, 'yes', 'yes');
    }
    if (!allTokensLoading && !allTokensData) getAllTokenData();
  }, []);

  useEffect((): void => {
    window.scroll(0, 0);
  }, []);

  useEffect((): void => {
    if (amount && Number(amount) < withdrawSettings?.minimumTrxAmount) {
      setValidateErrorAmount(
        `Minimum ${withdrawSettings?.minimumTrxAmount} ${
          assets?.find((item: any): boolean => item?._id === selectedWallet)
            ?.token?.name
        } is required for withdraw`
      );
    } else if (
      amount &&
      Number(amount) >
        assets?.find((item: any): boolean => item?._id === selectedWallet)
          ?.balance
    ) {
      setValidateErrorAmount(
        `Withdrawal amount is exceed of your wallet balance`
      );
    } else {
      setValidateErrorAmount(null);
    }
  }, [amount, withdrawSettings]);

  useEffect((): (() => void) => {
    return (): void => {
      handleResetDeposit();
    };
  }, []);

  useEffect((): void => {
    setResetFilter(false);
    if (!getWalletDataDepositHistoryLoading) {
      (async (): Promise<void> => {
        await getWalletDataDepositHistory({
          page: 1,
          limit: 10,
          query: searchValue,
          fromDate: filters?.fromDate,
          toDate: filters?.toDate,
          status: selectedStatus,
          token: filters.token,
        });
      })();
    }
  }, [
    filters.fromDate,
    filters.toDate,
    filters.token,
    selectedStatus,
    searchValue,
    searchValue,
    isDeposited,
  ]);

  if (
    depositHistory !== null &&
    depositHistory?.list &&
    depositHistory?.list?.length &&
    resetDisable
  ) {
    setResetDisable(false);
  }

  const handleDepositHistory = useCallback(async (): Promise<void> => {
    if (!getWalletDataDepositHistoryLoading) {
      await getWalletDataDepositHistory({
        page: 1,
        limit: 10,
      });
    }
  }, [getWalletDataDepositHistoryLoading]);

  const type = (() => {
    switch (depositHistory !== null && depositHistory?.list[openIndex]?.type) {
      case 'deposit-and-stake-request':
        return 'deposit-and-stake-request';
      case 'deposit-and-stake':
        return 'deposit-and-stake';
      default:
        return 'Deposit';
    }
  })();

  const filteredDepositHistory =
    depositHistory !== null && depositHistory?.list.length
      ? depositHistory?.list?.filter(
          (item: any): boolean =>
            !(item?.status === 'failed' && item?.coin === 'USDT')
        )
      : [];

  return (
    <>
      {filteredDepositHistory && filteredDepositHistory.length > 0 && (
        <HistoryPopup
          open={open}
          onClose={(): void => {
            setOpen(false);
            setOpenIndex(0);
          }}
          headings={headCells}
          data={filteredDepositHistory[openIndex]}
          fromComponent={type}
          type={type}
        />
      )}

      <div className="flex flex-col gap-4">
        <div className="flex items-start justify-between">
          <GlobalHeading
            heading="Deposit"
            className="flex-1 !text-defaultBlack"
          />

          <GlobalButton
            classNames="border border-secondary rounded text-sm text-primary !w-32 !py-2.5 !px-4 !text-center !text-defaultBlack"
            containerClassName="md:hidden block"
            outlined={true}
            onClick={() => navigate('/wallet')}
            text="Go Back"
            StartIcon={<img src={back} alt="back" className="w-4 h-4 " />}
          />
        </div>
        <div className="space-y-6">
          {user?.kycApproved && (
            <Alert
              severity="info"
              icon={<img src={danger} alt="danger" />}
              sx={{
                '& .MuiAlert-message': {
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                },
              }}
            >
              <div className="py-2 text-primary">
                <span className="font-bold mr-0.5">Note:</span> Please note that
                only supported networks on{' '}
                <span className="font-bold mx-0.5">{PLATFORM_NAME}</span>{' '}
                platform are shown, if you deposit via another network your
                assets may be lost.{' '}
              </div>
            </Alert>
          )}
        </div>

        <div className="flex flex-col gap-4 xl:flex-row">
          <HoverBorderAnimation className="flex flex-col w-full gap-6 p-4 bg-white border shadow-sm border-borderBlue rounded-2xl lg:p-8 radius-22px ">
            <DepositForm
              setIsDeposited={setIsDeposited}
              handleDepositHistory={handleDepositHistory}
            />
          </HoverBorderAnimation>

          <HoverBorderAnimation className="flex flex-col w-full gap-1 p-4 bg-white border shadow-sm rounded-2xl border-borderBlue radius-22px ">
            <GlobalSubHeading text={'FAQ'} />
            <hr className="mt-4" />
            <div
              className={`flex flex-col gap-3 topup ${
                showHistory ? 'max-h-[35rem]' : 'h-[35rem]'
              }`}
            >
              {showHistory ? (
                <>
                  {isLoading || !depositFaqs ? (
                    <>
                      {Array.from(
                        { length: 1 },
                        (_: unknown): ReactElement => (
                          <div className="space-y-4">
                            <Skeleton
                              variant="rectangular"
                              animation={'wave'}
                              height={60}
                              sx={{ borderRadius: 2 }}
                            />
                            <Skeleton
                              variant="rectangular"
                              animation={'wave'}
                              height={90}
                              sx={{ borderRadius: 2 }}
                            />
                          </div>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {/* <AllAccordian data={depositFaqs} /> */}
                      <FAQAccordian data={depositFaqs} />
                    </>
                  )}
                </>
              ) : (
                <NoData visible={true} />
              )}
            </div>

            {depositFaqs && depositFaqs.length === 0 && (
              <EmptyPage
                title="Sorry, No Faqs Found."
                message="Its getting boring in here. Time to treat yourself."
              />
            )}
          </HoverBorderAnimation>
        </div>

        <div className="flex flex-col gap-10 mb-20">
          <CardLayout className="col-span-12" isBorder={false}>
            <section className="flex flex-col items-start justify-between gap-4">
              <GlobalTable
                isWallet={true}
                selectedButton={'Deposit'}
                pagination={false}
                rows={
                  filteredDepositHistory.length
                    ? filteredDepositHistory.map((item: any) => {
                        return {
                          ...item,
                          time: item?.time ? tolocalTime(item?.time) : '',
                          status: <StatusChip status={item?.status} />,
                          hash: (
                            <span>
                              {item.hash &&
                              item?.hash !== 'internal' &&
                              item?.hash !== 'claimed-reward' ? (
                                <CopyItemsView
                                  cellValue={
                                    item?.hash +
                                    ' ' +
                                    (item?.fromUser?.blockchainId
                                      ? item?.fromUser?.blockchainId
                                      : '')
                                  }
                                />
                              ) : item.hash ? (
                                item?.hash +
                                ' ' +
                                (item?.fromUser?.blockchainId
                                  ? item?.fromUser?.blockchainId
                                  : '-')
                              ) : (
                                '-'
                              )}
                            </span>
                          ),
                        };
                      })
                    : []
                }
                headCells={headCells.filter(
                  (cell: any): boolean =>
                    !['previousBalance', 'newBalance'].includes(cell.id)
                )}
                isLoading={
                  !depositHistory ? getWalletDataDepositHistoryLoading : false
                }
                searchIsLoading={false}
                resetDateFilter={resetFilter}
                page={filters.page}
                totalPages={
                  (depositHistory !== null && depositHistory?.totalPages) || 1
                }
                handlePageChange={(page: number): void => {
                  setFilters({ ...filters, page });
                  getWalletDataDepositHistory({ ...filters, page, limit: 10 });
                }}
                copyItems={['destination']}
                handleDateDate={handleDateDate}
                emptyDataMessage="No Purchase History!"
                setSearchValue={(e: any): void => setSearchValue(e ? e : null)}
                searchValue={searchValue}
                showTotalRecords={false}
                searchFieldPlaceholderText="Enter ID"
                paginationPosition={'right'}
                toolTipItems={['remarks']}
                colorItems={['status']}
                tableDropdowns={
                  <div className="flex flex-row flex-wrap space-x-3 sm:items-center">
                    <GlobalSelect
                      name="status"
                      placeholder="Status"
                      isError={false}
                      value={selectedStatus}
                      getSelectedValue={(item: string): void => {
                        setSelectedStatus(item);
                      }}
                      options={[
                        { name: 'Pending', value: 'pending' },
                        { name: 'Success', value: 'success' },
                        { name: 'Failed', value: 'failed' },
                        { name: 'Expired', value: 'expired' },
                        { name: 'Partial-Success', value: 'partial-success' },
                        { name: 'Cancelled', value: 'cancelled' },
                      ]}
                      className="w-32"
                      classNameSelect="rounded-lg py-3 px-0 !border-defaultBorder"
                      selectdisable={resetDisable}
                    />
                    <GlobalSelect
                      name="token"
                      placeholder="Token"
                      isError={false}
                      value={filters.token}
                      getSelectedValue={(item: string): void => {
                        setFilters((prev: any) => ({
                          ...prev,
                          token: item,
                        }));
                      }}
                      options={
                        allTokensData?.tokens?.length
                          ? allTokensData?.tokens?.map((tkn: any) => {
                              return {
                                name: tkn?.name,
                                value: tkn?.symbol,
                              };
                            })
                          : []
                      }
                      className="w-32"
                      classNameSelect="rounded-lg py-3 px-0"
                    />
                    <button
                      className={`${resetDisable || filterEmptyCheck() ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10`}
                      type="button"
                      disabled={resetDisable || filterEmptyCheck()}
                      onClick={(): void => {
                        // setDate({
                        //   fromDate: null,
                        //   toDate: null
                        // })
                        setResetFilter(true);
                        setFilters({
                          limit: 10,
                          page: 1,
                          timeperiod: 'alltime',
                          type: 'all',
                          totalPages: 0,
                          totalCount: 0,
                          fromDate: null,
                          toDate: null,
                          token: null,
                        });
                        setSearchValue('');
                        setSelectedStatus('');
                        setDateChanged(false);
                      }}
                    >
                      Reset
                    </button>
                    {filteredDepositHistory?.length ? (
                      <div
                        className="absolute justify-end right-0 gap-1 flex cursor-pointer self-end "
                        onClick={(): void => {
                          navigate('/wallet/history', {
                            state: { activePage: 'Deposit' },
                          });
                        }}
                      >
                        <span className="text-sm font-normal">View All</span>
                        <div className="w-5 h-5">
                          <ArrowRight />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                }
                handleRowClick={(index: number): void => {
                  setOpen(true);
                  setOpenIndex(index);
                }}
                tableContainerClassname="lg:!block !rounded-lg"
                mainContainerClassnames="relative"
                productAsACol={false}
                searchdisable={resetDisable}
                filterSearchClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
                fromDateDisable={resetDisable}
                fromDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
                toDateDisable={resetDisable}
                toDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''}`}
                setDateChanged={setDateChanged}
              />
              <AuthOTP
                vCode={vCode}
                setVCode={setVCode}
                isOpen={otpOpen}
                onClose={(): void => {
                  setVCode(Array(6).fill(null));
                  setOtpOpen(false);
                }}
                handleContinueClick={(): void => {
                  const isOTPNotValid = otpValidaty(vCode);
                  if (isOTPNotValid) {
                    return setErrorMessage(isOTPNotValid);
                  }
                  handleSubmit();
                }}
                isVerifying={isVerifying}
                errorMessage={errorMessage}
                otpMode={otpMode}
              />

              <GlobalSuccess
                open={successDialog}
                closeDialog={(): void => {
                  setSuccessDialog(false);
                  // Reset the necessary states
                  setAmount('');
                  setSelectedWallet('');
                  setSelectedUSDT('');
                  setSelectedNetwork('');
                  setVCode(Array(6).fill(null));
                }}
              />
            </section>
          </CardLayout>
        </div>
      </div>
    </>
  );
}
