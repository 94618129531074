import { useState } from 'react';
import HowToRedeemModal from './HowToRedeemModal';
import { Info2 } from '../../../images/svgs';

const ModalWrapper = () => {
    const [openRedeemModal, setOpenRedeemModal] = useState<boolean>(false);

    return (
        <>
            <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => setOpenRedeemModal(true)}
            >
                <span className="text-lg font-normal underline text-primary font-baumans whitespace-nowrap">
                    How to Redeem?
                </span>
                <Info2 />
            </div>
            <HowToRedeemModal isOpen={openRedeemModal} onClose={() => setOpenRedeemModal(false)} />
        </>
    );
};
export default ModalWrapper;
