import GlobalModal from './Global/GlobalModal';
import GlobalButton from './Global/GlobalButton';
import tinycolor from 'tinycolor2';
import { Spinner } from '../images/svgs';
import { setDecimalPlaces } from '../utils/helper';
import Divider from './Divider';
import { selectDueAmount } from '../store/general/generalSlice';
import { useSelector } from 'react-redux';
import { selectRewardSlice } from '../store/Cloudk2/rewardCalculatorSlice';

interface Props {
  open: boolean;
  onClose: () => void;
  loading?: boolean;
  handleSubmit: () => void;
  buttonText: string;
  withdrawAmount: number;
  amountText: string;
}

const DueAmountModal = ({
  open,
  onClose,
  loading = false,
  handleSubmit,
  buttonText = 'Proceed',
  withdrawAmount,
  amountText = 'Amount',
}: Props) => {
  const dueAmount = useSelector(selectDueAmount);

  const amountConversion = setDecimalPlaces(Number(withdrawAmount || 0), 6);
  const deductedAmount =
    (dueAmount &&
      (amountConversion > dueAmount?.balance
        ? dueAmount?.balance
        : amountConversion)) ||
    0;

  const receivableAmount = amountConversion - deductedAmount;

  const remainingDueBalance = () => {
    const remainingDue = dueAmount && dueAmount?.balance - deductedAmount;
    return remainingDue;
  };

  return (
    <GlobalModal
      isOpen={open}
      onClose={onClose}
      size="xl"
      title={dueAmount?.token?.name}
    >
      <div className="flex flex-col items-center justify-center w-full gap-3 pb-8">
        <div className="flex items-start justify-between w-full gap-3 mb-4">
          <div className="flex flex-col w-[70%] gap-5">
            <p className="text-sm text-gray-500">
              You have an outstanding balance that must be settled before
              proceeding.
            </p>
            <div className="flex items-center w-full gap-3">
              <h3 className="font-medium">{amountText}:</h3>
              <span className="font-semibold text-primary">
                $ {setDecimalPlaces(Number(amountConversion || 0), 6)}
              </span>
            </div>
            <div className="flex items-center w-full gap-3">
              <h3 className="font-medium">Deducted Amount:</h3>
              <span className="font-semibold text-primary">
                $ {setDecimalPlaces(Number(deductedAmount || 0), 6)}
              </span>
            </div>
            <Divider />
            <div className="flex items-center w-full gap-3">
              <h3 className="font-medium">
                Remaining {dueAmount?.token?.name} Balance:
              </h3>
              <span className="font-semibold text-primary">
                $ {setDecimalPlaces(Number(remainingDueBalance() || 0), 6)}
              </span>
            </div>
            <div className="flex items-center w-full gap-3">
              <h3 className="font-medium">Receivable Amount:</h3>
              <span className="font-semibold text-primary">
                $ {setDecimalPlaces(Number(receivableAmount || 0), 6)}
              </span>
            </div>
          </div>

          <div
            className={`relative flex flex-col gap-7 py-4 rounded-xl w-[30%] h-[180px] `}
            style={{
              background: `linear-gradient(${dueAmount?.token?.color}, ${dueAmount?.token?.color}), url('/img/wallets/bg-opacity-class.png')`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundBlendMode: 'overlay, overlay',
            }}
          >
            <div className="flex flex-col items-center justify-center w-full gap-4">
              <img
                className={`w-8 h-8 shadow-logoImageBoxShadow rounded-full `}
                src={dueAmount?.token?.iconUrl}
                alt="token-img"
              />
              <div
                className="px-4 py-1 text-sm font-bold text-black border"
                style={{
                  borderColor: tinycolor(dueAmount?.token?.color)
                    .darken(20)
                    .toString(),
                  backgroundColor: tinycolor(dueAmount?.token?.color)
                    .lighten(30)
                    .toString(),
                  borderRadius: '32px',
                }}
              >
                <span>{dueAmount?.token?.name} Balance</span>
              </div>
            </div>
            <div className="flex items-center justify-center px-1">
              <span className="overflow-hidden text-lg font-semibold whitespace-nowrap text-ellipsis">
                {`
                    $ ${setDecimalPlaces(Number(dueAmount?.balance || 0), 6)}`}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-3">
          <GlobalButton
            outlined
            classNames="bd-primary"
            text="Close"
            onClick={onClose}
          />
          <GlobalButton
            loading={loading}
            disabled={loading}
            classNames="bd-primary"
            text={buttonText}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </GlobalModal>
  );
};

export default DueAmountModal;
