import { ResponsiveLine } from '@nivo/line';
import { TChartDataType } from '../../types/Charts/lineChart.types';
import {
  formatNumberWithCommas,
  toFixedFormat,
} from '../../../utils/utils';

type Props = {
  containerClassName?: string;
  isCloudKChart?: boolean;
  data: TChartDataType;
  margin?: object;
  enablePoints?: boolean;
  defs?:
  | {
    [key: string]: any;
    id: string;
  }[]
  | undefined;
  fill?:
  | {
    id: string;
    match: Record<string, unknown> | '*' | any;
  }[]
  | undefined;
  colors?: string[];
  axisTop?: null | object;
  axisRight?: null | object;
  axisBottom?: null | object;
  axisLeft?: null | object;
  enableGridY?: boolean;
  enableGridX?: boolean;
  isInteractive?: boolean;
  tooltipText?: string;
  legends?: any;
  tootlipColor?: string;
  showSub?: boolean;
  enableArea?: boolean;
  theme?: any;
  tootlipText?: string;
  containerClass?: string;
  gridXValues?: any;
  gridYValues?: any;
  curve?: any;
  areaOpacity?: number;
  xScale?: any;
  yScale?: any;
  pointSize?: any;
  pointColor?: any;
  pointBorderWidth?: any;
  pointLabel?: any;
  pointLabelYOffset?: any;
  pointBorderColor?: any;
};

const CustomSliceTooltip = ({ slice }: any) => (
  <div
    style={{
      background: 'black',
      padding: '9px 12px',
      border: '1px solid #ccc',
      width: '300px',
    }}
  >
    <strong>{slice.points[0].data.xFormatted}</strong>
    {slice?.points?.map((point: any) => (
      <div
        key={point.id}
        style={{
          color: point.serieColor,
          padding: '3px 0',
        }}
      >
        <strong>{point.serieId}</strong> [{point.data.yFormatted}]
      </div>
    ))}
  </div>
);

const LineChart = ({
  data,
  margin = { top: 20, bottom: 50, left: 45, right: 20 },
  enablePoints = false,
  isCloudKChart = false,
  defs,
  fill,
  containerClass = '',
  colors = ['#fff', '#EB6535'],
  axisTop,
  axisBottom = {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'x-axis legend',
    legendOffset: 36,
    legendPosition: 'middle',
  },
  axisLeft = {
    orient: 'left',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: 'y-axis legend',
    legendOffset: -40,
    legendPosition: 'middle',
    format: (value: any) => `${value} units`, // Ensure this works as expected
  },
  axisRight,
  enableGridX = true,
  enableGridY = true,
  xScale,
  isInteractive = true,
  legends = [
    {
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 100,
      translateY: 0,
      itemsSpacing: 0,
      itemDirection: 'left-to-right',
      itemWidth: 80,
      itemHeight: 20,
      itemOpacity: 0.75,
      symbolSize: 12,
      symbolShape: 'circle',
      symbolBorderColor: 'rgba(0, 0, 0, .5)',
      effects: [
        {
          on: 'hover',
          style: {
            itemBackground: 'rgba(0, 0, 0, .03)',
            itemOpacity: 1,
          },
        },
      ],
    },
  ],
  tootlipColor,
  showSub,
  enableArea,
  theme,
  tootlipText = '',
  gridXValues,
  gridYValues,
  curve = 'linear',
  areaOpacity = 0.35,
  yScale,
  pointSize,
  pointColor,
  pointBorderWidth,
  pointLabel,
  pointLabelYOffset,
  pointBorderColor,
  containerClassName,
}: Props) => {
  const yValues =
    data?.reduce<number[]>((acc, series) => {
      series.data.forEach((point) => {
        if (point.y !== null) {
          acc.push(point.y);
        }
      });
      return acc;
    }, []) || [];
  const maxY = Math.max(...yValues);
  const minY = Math.min(...yValues);

  const yScaleMin = minY ? Math.floor(minY) : 0;

  const yScaleMax = maxY ? Math.ceil(maxY) : 1;

  return (
    <div
      className={`${containerClassName ? '' : 'xxs:px-0 md:px-3'} flex flex-col md:flex-row items-center justify-between gap-2 md:gap-4 bg-bgSecondary rounded-lg w-full h-full  ${containerClass}`}
    >
      <div className="w-full h-full md:w-full md:h-full text-black">
        <ResponsiveLine
          data={data}
          areaOpacity={areaOpacity}
          enableGridX={enableGridX}
          enableGridY={enableGridY}
          pointLabelYOffset={pointLabelYOffset}
          margin={margin}
          layers={[
            'grid',
            'lines',
            'areas',
            'crosshair',
            'markers',
            'mesh',
            'points',
            'slices',
            'legends',
            'axes',
          ]}
          xScale={xScale || { type: 'point' }}
          yScale={
            yScale || {
              type: 'linear',
              min: yScaleMin,
              max: yScaleMax,
              // nice: true,
              // min: 0,
            }
          }
          isInteractive={isInteractive}
          pointSize={pointSize || 0}
          pointColor={pointColor || ''}
          pointBorderWidth={pointBorderWidth || 0}
          pointBorderColor={pointBorderColor || 10}
          enablePointLabel={false}
          pointLabel={pointLabel || ''}
          areaBlendMode={'normal'}
          areaBaselineValue={yScaleMin}
          lineWidth={2}
          legends={legends}
          debugMesh={false}
          enableSlices={false}
          debugSlices={false}
          sliceTooltip={CustomSliceTooltip}
          enablePoints={enablePoints}
          enableCrosshair={false}
          crosshairType="x"
          role="tooltip"
          theme={
            theme || {
              text: {
                fontSize: '12px',
                fill: '#AAAAAA',
              },
              grid: {
                line: {
                  stroke: '#FFFFFF40',
                  strokeWidth: 1,
                },
              },
              axis: {
                ticks: {
                  line: {
                    stroke: '#FFFFFF40',
                  },
                  text: {
                    fontSize: '8px',
                    fill: '#7D7D7D',
                  },
                },
              },
            }
          }
          colors={colors}
          enableArea={enableArea}
          gridXValues={gridXValues}
          gridYValues={gridYValues}
          defs={defs}
          fill={fill}
          tooltip={({ point }: any) => {
            return (
              <>
                {isCloudKChart ? (
                  <div
                    className="bg-white flex flex-col px-[5px] py-2 gap-1"
                    style={{
                      border: `1px solid ${point.borderColor}`,
                    }}
                  >
                    <span className="text-[#101010] font-bold text-[9px]">
                      {
                        toFixedFormat(
                          Number(point.data.y || 0),
                          2
                        )
                      }{' '}
                      {point?.serieId}
                    </span>
                    <span className="text-[#616161] text-[8px]">{point.data.x}</span>
                  </div>
                ) : (
                  <div className="relative flex flex-col items-center justify-center gap-1 w-fit">
                    <div
                      className={`relative flex flex-col gap-1 border border-[#7583FF] rounded-lg p-2 min-w-[80px] max-w-[200px] mx-auto bg-white`}
                    >
                      <div className="flex gap-1">
                        <p className="text-xs font-normal text-secondaryBlack">
                          {point?.data?.y.toLocaleString()}
                        </p>
                      </div>

                      {showSub && (
                        <p className="text-xs text-[#939393]">
                          {point?.data?.x}
                        </p>
                      )}

                      <div
                        className={`w-0 h-0 border-l-[8px] border-r-[8px] border-t-[10px] absolute left-1/2 transform -translate-x-1/2 bottom-[-10px]`}
                        style={{
                          borderTopColor: '#7583FF',
                          borderLeftColor: 'transparent',
                          borderRightColor: 'transparent',
                          backgroundColor: 'transparent',
                        }}
                      />

                      <div
                        className={`w-2 h-2 rounded-full border bg-[#7583FF] absolute left-1/2 transform -translate-x-1/2 bottom-[-20px]`}
                      />
                    </div>
                  </div>
                )}
              </>
            );
          }}
          axisTop={axisTop}
          axisRight={axisRight}
          axisLeft={axisLeft}
          axisBottom={axisBottom}
          enableTouchCrosshair={true}
          useMesh={true}
          curve={curve}
        />
      </div>
    </div>
  );
};

export default LineChart;
