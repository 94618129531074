import { Skeleton } from '@mui/material';
import GlobalDivider from '../../../components/Global/GlobalDivider';
import { NoDataImage } from '../../../images/Dashboard';
import RangeDatePicker from '../../Wallet/RangeDatePicker';
import GlobalSearch from '../../../components/Global/GlobalSearch';
import PaginationButtons from '../../../components/Global/GlobalPaginatedButtons';
import HoverBorderAnimation from '../../../components/HoverBorderAnimation';
import GlobalModal from '../../../components/Global/GlobalModal';
import useModalHook from '../../../hooks/useModalHook';
import TreeNodes from './TreeNodes';
import {
  capitalizeFirstLetter,
  formatAlphaNumericDate,
  truncateToSixDecimals,
} from '../../../utils/utils';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectSuperNodeSlice } from '../../../store/superNode/superNodeSlice';
import CsvDownload from 'react-csv-downloader';
import { DownloadIcon } from '../../../images/svgs';

type Props = {
  rows: any[];
  isLoading: boolean | undefined;
  totalPages: number;
  handlePageChange: (num: number) => void;
  page: number;
  tableTitle?: string;
  handleSearch: (e: string) => void;
  searchValue: string;
  searchIsLoading: boolean;
  filterSearchBaseClassName: string;
  filterSearchClassName?: string;
  searchdisable?: boolean;
  handleDateDate: (fromDate: string, toDate: string) => void;
  fromDate?: string;
  toDate?: string;
  resetDateFilter: any;
  handleReset: any;
  filterEmptyCheck: any;
  toDateClassName?: string;
  fromDateClassName?: string;
  dateArrowAllow: boolean;
  fromDateDisable?: boolean;
  toDateDisable?: boolean;
  setDateChanged: any;
  DownloadCSV: any;
  tableDropdowns?: any;
  selectedReward?: any;
  selectedButton?: string;
  initialEndDate?: Date;
  initialStartDate?: Date;
  csvColumns?: any;
  type?: string;
};

const headCells = [
  { id: 'fromuser', label: 'From User', dataClass: ' lg:!w-[25%]' },
  {
    id: 'rewardLevel',
    label: 'Reward Level',
    dataClass: 'lg:!w-[10%] text-center ',
  },
  { id: 'type', label: 'Type', dataClass: 'lg:!w-[15%]' },
  {
    id: 'treeLevel',
    label: 'Tree Level',
    dataClass: 'lg:!w-[10%] text-center ',
  },
  { id: 'rewardData', label: 'Reward', dataClass: 'lg:!w-[15%]' },
  {
    id: 'RemainingGASK',
    label: 'Remaining GASK',
    dataClass: 'lg:!w-[15%]',
  },
  { id: 'date', label: 'Date', dataClass: 'lg:!w-[20%] lg:min-w-[180px]' },
  { id: 'action', label: 'Action', dataClass: 'lg:!w-[15%]' },
];

const SuperNodeTable = ({
  rows = [],
  isLoading,
  handlePageChange,
  totalPages,
  page,
  tableTitle,
  handleSearch,
  searchValue,
  searchIsLoading,
  filterSearchBaseClassName,
  filterSearchClassName,
  searchdisable,
  handleDateDate,
  handleReset,
  filterEmptyCheck,
  resetDateFilter,
  toDateClassName,
  fromDateClassName,
  dateArrowAllow,
  fromDateDisable,
  fromDate,
  toDate,
  toDateDisable,
  setDateChanged,
  DownloadCSV,
  selectedButton,
  tableDropdowns,
  selectedReward,
  initialEndDate,
  initialStartDate,
  csvColumns,
  type,
}: Props) => {
  const { open, handleClose, handleOpen } = useModalHook();
  const {
    superNodeTotalRewadsData,
    superNodeSuperNodeRewardsLoading,
    totalBonus,
    getTotalBonusLoading,
  } = useSelector(selectSuperNodeSlice);

  return (
    <div className="w-full">
      <div className="flex flex-col w-full h-full gap-5">
        <div className={`flex items-center flex-wrap justify-between w-full`}>
          <article className="flex flex-col justify-between w-full gap-2 sm:flex-row">
            <div className="flex flex-col md:flex-row flex-wrap md:items-center flex-1 gap-3 w-full">
              <GlobalSearch
                placeholderText={'Search'}
                value={searchValue}
                handleOnSearch={(e) => {
                  handleSearch(e.target.value);
                }}
                isLoading={searchIsLoading ? true : false}
                baseClassName={` ${filterSearchBaseClassName}`}
                inputClassName={filterSearchClassName}
                disabled={searchdisable}
              />
              <div className="flex flex-wrap gap-3">
                <RangeDatePicker
                  handleDateDate={handleDateDate}
                  toDateClassName={toDateClassName}
                  fromDateClassName={fromDateClassName}
                  dateArrowAllow={dateArrowAllow}
                  fromDateDisable={fromDateDisable}
                  resetFilter={resetDateFilter}
                  toDateDisable={toDateDisable}
                  setDateChanged={setDateChanged}
                  initialEndDate={initialEndDate}
                  initialStartDate={initialStartDate}
                />
              </div>
              {tableDropdowns && tableDropdowns}
              <button
                className={`${filterEmptyCheck() ? 'cursor-not-allowed text-primaryGrey' : 'text-primary'} text-xs font-medium h-10 ml-2 flex-grow`}
                type="button"
                onClick={handleReset}
                disabled={filterEmptyCheck()}
              >
                Reset
              </button>
              {totalBonus && (
                <div className="w-fit self-end">
                  <CsvDownload
                    className="flex items-center gap-1.5 text-white bg-primary hover:bg-primary/90 px-3 py-1.5 rounded-lg text-sm cursor-pointer "
                    datas={DownloadCSV}
                    filename={type + '.csv'}
                    columns={csvColumns?.label}
                  >
                    <DownloadIcon className="w-4 h-4 text-white" />
                    <span>Download</span>
                  </CsvDownload>
                </div>
              )}
            </div>

            {/* <GetCSVData
                            disabled={rows?.length <= 0}
                            transactionData={rows}
                            fileName={`CloudkTransactionhistory.csv`}
                            classes="flex justify-end"
                            onclick={() => {
                                //   if (rows?.length > 0) {
                                //     getFilteredApiData().then((data: any) => {
                                //       filterDownloadData(data);
                                //     });
                                //   }
                            }}
                            onDownloadReady={() => {
                                // Callback function to handle any additional logic if needed
                            }}
                        /> */}
          </article>
        </div>

        <div className="flex overflow-x-auto  overflow-y-hidden items-center justify-between px-4 py-3 md:py-2 space-y-2 md:space-y-0 md:h-[60px] bg-white border-t-4 shadow-lg w-full rounded-md border-primary">
          {selectedButton === 'total-reward' ? (
            <>
              {!getTotalBonusLoading ? (
                <div className="flex justify-between gap-4 flex-1">
                  <div>
                    <h3 className="block text-black text-[13px] font-medium text-nowrap">
                      Total Reward
                    </h3>
                    <div className="text-[14px] text-[#1B1B1B] text-nowrap">
                      {totalBonus?.total?.totalReward !== undefined
                        ? Number(
                          truncateToSixDecimals(
                            totalBonus?.total?.totalReward ?? 0,
                            totalBonus?.total?.totalReward >= 1
                              ? 2
                              : totalBonus?.total?.totalReward === 0
                                ? 0
                                : 6
                          )
                        )
                        : 0}{' '}
                      LYK-W
                    </div>
                  </div>

                  <div>
                    <h3 className="block text-black text-[13px] font-medium text-nowrap">
                      Base Referral
                    </h3>
                    <div className="text-[14px] text-[#1B1B1B] text-nowrap">
                      {totalBonus?.total?.baseRefferal !== undefined
                        ? Number(
                          truncateToSixDecimals(
                            totalBonus?.total?.baseRefferal ?? 0,
                            totalBonus?.total?.baseRefferal >= 1
                              ? 2
                              : totalBonus?.total?.baseRefferal === 0
                                ? 0
                                : 6
                          )
                        )
                        : 0}{' '}
                      LYK-W
                    </div>
                  </div>

                  <div>
                    <h3 className="block text-black text-[13px] font-medium text-nowrap">
                      Builder Generational
                    </h3>
                    <div className="text-[14px] text-[#1B1B1B] text-nowrap">
                      {totalBonus?.total?.builderGenerational !== undefined
                        ? Number(
                          truncateToSixDecimals(
                            totalBonus?.total?.builderGenerational ?? 0,
                            totalBonus?.total?.builderGenerational >= 1
                              ? 2
                              : totalBonus?.total?.builderGenerational === 0
                                ? 0
                                : 6
                          )
                        )
                        : 0}{' '}
                      LYK-W
                    </div>
                  </div>

                  <div>
                    <h3 className="block text-black text-[13px] font-medium text-nowrap">
                      Matching Bonus
                    </h3>
                    <div className="text-[14px] text-[#1B1B1B] text-nowrap">
                      {totalBonus?.total?.matchingBonus !== undefined
                        ? Number(
                          truncateToSixDecimals(
                            totalBonus?.total?.matchingBonus ?? 0,
                            totalBonus?.total?.matchingBonus >= 1
                              ? 2
                              : totalBonus?.total?.matchingBonus === 0
                                ? 0
                                : 6
                          )
                        )
                        : 0}{' '}
                      LYK-W
                    </div>
                  </div>

                  <div>
                    <h3 className="block text-black text-[13px] font-medium text-nowrap">
                      From Date
                    </h3>
                    <div className="text-[14px] text-[#1B1B1B] text-nowrap">
                      {formatAlphaNumericDate(totalBonus?.fromDate) || 'N/A'}
                    </div>
                  </div>

                  <div>
                    <h3 className="block text-black text-[13px] font-medium text-nowrap">
                      To Date
                    </h3>
                    <div className="text-[14px] text-[#1B1B1B] text-nowrap">
                      {formatAlphaNumericDate(totalBonus?.toDate) || 'N/A'}
                    </div>
                  </div>
                </div>

              ) : (
                <div className="flex items-center justify-between flex-shrink-0 w-full">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={30}
                    className="mx-2 bg-gray-300"
                    animation="wave"
                  />
                </div>
              )}
            </>
          ) : (
            <>
              {!getTotalBonusLoading ? (
                <div className="flex items-center justify-between w-full">
                  <h3 className="block text-black text-[13px] font-medium text-nowrap">
                    Total&nbsp;
                    {selectedButton &&
                      capitalizeFirstLetter(
                        selectedButton
                          .replace(/-/g, ' ')
                          .replace(/\b\w/g, (char) => char.toUpperCase()) ?? ''
                      )}
                  </h3>
                  <div className="text-[14px] text-[#1B1B1B] text-nowrap">
                    {totalBonus?.total !== undefined
                      ? Number(
                        truncateToSixDecimals(
                          totalBonus?.total ?? 0,
                          totalBonus?.total >= 1
                            ? 2
                            : totalBonus?.total === 0
                              ? 0
                              : 6
                        )
                      )
                      : 0}{' '}
                    LYK-W
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-between flex-shrink-0 w-full">
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={30}
                    className="mx-2 bg-gray-300"
                    animation="wave"
                  />
                </div>
              )}
            </>
          )}
        </div>

        <HoverBorderAnimation className="w-full h-full bg-white border border-borderGray !rounded-md">
          <div className="hidden lg:block  flex-col items-center gap-2 overflow-x-auto     !rounded-md   overflow-y-hidden">
            <table className="w-full h-full bg-white rounded-md min-w-[1000px]">
              <thead className="sticky top-0 z-30 w-full table-fixed">
                <tr
                  className={'w-full h-[2.5rem] font-medium text-xs text-black'}
                >
                  {headCells.map((cell, index) => (
                    <th
                      key={index}
                      className={`hidden md:table-cell text-left font-semibold text-xs p-3 border-b border-borderGray  ${cell.dataClass} `}
                    >
                      {cell.label}
                    </th>
                  ))}
                </tr>
              </thead>
              {isLoading ? (
                <tbody className="w-full">
                  <tr className={`font-medium text-sm w-full `}>
                    {headCells.map((cell, index) => (
                      <td
                        key={index}
                        className="table-cell font-medium text-xs text-textSecondary w-[10%] py-3 border-b"
                      >
                        <Skeleton
                          variant="rounded"
                          width={'80%'}
                          height={40}
                          className="mx-2"
                          animation={'wave'}
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              ) : (
                <>
                  <tbody className="w-full min-h-[15rem] max-h-[30rem] overflow-y-auto small-scroll">
                    {rows.map((row, index) => {
                      return (
                        <tr
                          key={index}
                          className={`font-medium text-sm w-full`}
                        >
                          {headCells.map((cell, index) => (
                            <td
                              key={index}
                              className={`table-cell p-3 font-normal ${cell.id == 'rewardLevel' ? 'text-center xl:text-left' : 'text-left'} text-xs text-textSecondary py-3 border-b`}
                            >
                              <p
                                onClick={() => {
                                  cell.id === 'action' && handleOpen();
                                }}
                              >
                                {cell.id === 'rewardData'
                                  ? `${row[cell.id]} LYK-W`
                                  : row[cell.id]}
                              </p>
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              )}
            </table>
          </div>
          <div className="flex flex-col gap-3 lg:hidden">
            <div className="flex flex-col w-full gap-2 text-xs text-white">
              {isLoading &&
                headCells.map((cell, index) => (
                  <div key={index} className="flex items-center w-full">
                    <div className="w-1/3">{cell.label}:</div>
                    <div className="w-2/3">
                      <Skeleton
                        variant="rounded"
                        height={20}
                        animation={'wave'}
                      />
                    </div>
                  </div>
                ))}
              {rows?.map((row, index) => (
                <div key={index} className="flex flex-col gap-2">
                  {headCells.map((cell, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between w-full px-4 py-2"
                    >
                      <div className="w-1/3 text-black">{cell.label}:</div>
                      <div
                        className="flex justify-end w-2/3 overflow-scroll text-black"
                        onClick={() => {
                          cell.id === 'action' && handleOpen();
                        }}
                      >
                        {row[cell.id]}
                      </div>
                    </div>
                  ))}
                  <GlobalDivider className="my-3 text-white" />
                </div>
              ))}
            </div>
          </div>

          {!isLoading && rows.length <= 0 && (
            <div className="flex flex-col justify-center items-center h-full min-h-[250px] gap-2">
              <img
                src={NoDataImage}
                alt="No Data"
                className="h-auto max-w-full"
              />
              <span className={`text-sm text-gray-500 `}>No Reports</span>
            </div>
          )}

          {totalPages > 1 && (
            <div className="flex justify-end px-4 mt-5">
              <PaginationButtons
                totalPage={totalPages}
                page={page}
                onChange={handlePageChange}
              />
            </div>
          )}
          <GlobalModal
            isOpen={open}
            onClose={handleClose}
            closeButtonClass="!text-white"
            size="5xl"
            childrenClass="p-6 lg:!py-10 lg:!px-16 lg:!max-h-[650px] overflow-y-auto small-scroll"
            title={
              selectedReward?.type === 'base-referral'
                ? 'Base Referral'
                : 'Builder Generational'
            }
            noHeader={false}
            rounded="rounded-sm"
            headerClassName="!py-2 !px-4 font-medium text-lg text-white bg-gradient-primary bg-gradient-primary-blue rounded-t-sm"
          >
            <TreeNodes selectedReward={selectedReward} />
          </GlobalModal>
        </HoverBorderAnimation>
      </div>
    </div>
  );
};

export default SuperNodeTable;
