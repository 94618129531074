import { useSelector } from 'react-redux'
import { ReactComponent as Spinner } from '../../../../../SVGIcons/spinner.svg'
import CardLayout from '../../../../../components/Cards/CardLayout'
import GlobalButton from '../../../../../components/Global/GlobalButton'
import CustomSwitch from '../../../../../components/Global/GlobalSwitch'
import Tooltips from '../../../../../components/Tooltips'
import { ClaimIcon } from '../../../../../images/svgs'
import { useCloudkActions } from '../../../../../store/cloudk/cloudkAction'
import { selectCloudSlice } from '../../../../../store/cloudk/cloudkSlice'
import { toFixedFormat } from '../../../../../utils/utils'
import { mapColumns } from '../../shared/ActivityHistoryTable/utils'
import { setDecimalPlaces } from '../../../../../utils/helper'


const RewardGenerated = () => {
  const { claimRewardLoading, isGenerationSwitch, claimableRewardLoading, claimableRewardAutoLinkLoading, claimableRewardData, transactionHistoryType, transactionHistoryFilters } = useSelector(selectCloudSlice)
  const { cloudKClaimableRewardAutoLinkFunc, claimCloudkRewardFunc, getCloudKTransactionHistoryV2 } = useCloudkActions()
  const data = claimableRewardData ?? []
  const allRewardsAreZeroOrFalsy = Object.entries(data)?.every(
    ([key, reward]: any) => !reward?.totalTokenReward
  );
  const tabs = Object.keys(mapColumns).map((key) => ({
    label: key.replace('_', ' '),
    key,
  }));
  return (
    <CardLayout className="md:col-span-5 col-span-12 p-5 flex flex-col gap-9 !shadow-boxShadow bg-[#FFFFFF]">
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <h1 className="text-secondaryBlack font-medium sm:text-lg text-sm">
            Claimable Rewards
          </h1>
        </div>
        <span className="text-cardSecondaryText sm:text-sm text-xs font-normal flex-grow text-justify ">
          Claim generated rewards with{' '}
          {(data?.tokens ?? [])
            .map((token: any) => token.tokenName)
            .join(' and ')}{' '}
          tokens based on Auto Link settings.
        </span>
      </div>

      {claimableRewardLoading && data?.length === 0 ? (
        <div className="flex justify-center items-center flex-1  h-16 w-full">
          <div className="justify-center items-center self-center">
            <div className="flex flex-col gap-5">
              <Spinner className="animate-spin h-6" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-10">
          <div className="  flex xl:flex-row xl:gap-12 lg:flex-row flex-col sm:flex-row sm:gap-12 lg:gap-4 gap-4 flex-wrap">
            {(data ?? []).map((token: any) => {
              return (
                <div className="flex gap-3" key={token._id}>
                  <div className="w-[34px] h-[34px]">
                    <img
                      src={token?.iconUrl}
                      alt="icon"
                      className="w-full h-full"
                    />
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-xl font-medium text-black">
                      {token?.tokenName}
                    </span>
                    <span
                      className={`text-2xl font-medium`}
                      style={{ color: token?.color }}
                    >
                      {toFixedFormat(Number(token?.totalTokenReward || 0), 3)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex justify-between gap-2 sm:items-center xl:items-center sm:flex-row md:flex-row xl:flex-row flex-col w-full">
            <div className="flex gap-3 w-full items-center">
              <span className="text-[#121212] text-base md:text-sm xl::text-base font-medium">
                Auto Link
              </span>
              {claimableRewardAutoLinkLoading ? (
                <div className="flex justify-center items-center  w-6 h-6">
                  <div className="justify-center items-center self-center">
                    <div className="flex flex-col gap-5">
                      <Spinner className="animate-spin h-6" />
                    </div>
                  </div>
                </div>
              ) : (
                <CustomSwitch
                  checked={isGenerationSwitch}
                  onChange={(value) => {
                    cloudKClaimableRewardAutoLinkFunc({
                      isAutoLink: value,
                    });
                  }}
                  className="reset-pass-switch"
                  parentClassName="reset-pass-switch-checked"
                />
              )}

              {/* <div className="flex items-center">
                            <label className="relative cursor-pointer">
                                <input type="checkbox" className="sr-only peer" />
                                <div
                                    className="w-[77px] border border-[#CFCFCF] h-[34px]  items-center bg-white rounded-full text-[9px]                 
                                    font-extrabold peer-checked:bg-[#9EEB5E] 
                  after:absolute after:left-1 after:top-1 after:bg-[#CFCFCF] peer-checked:after:bg-[#FFFFFF] 
                  after:rounded-full after:h-[27px] after:w-[27px] 
                  after:transition-transform peer-checked:after:translate-x-[43px]  justify-between px-2"
                                >                            
                                </div>
                            </label>
                        </div> */}
            </div>
            <div className="flex w-full justify-end">
              <Tooltips
                placement="top-end"
                showArrow={true}
                content={
                  data[0]?.totalTokenReward == 0 &&
                    data[1]?.totalTokenReward == 0 ? (
                    <div className="p-3 bg-white shadow-md rounded-lg text-xs ">
                      No Rewards Available to Claim!
                    </div>
                  ) : (
                    <></>
                  )
                }
              >
                <GlobalButton
                  text="Claim Rewards"
                  type="button"
                  disabled={claimRewardLoading || allRewardsAreZeroOrFalsy}
                  loading={claimRewardLoading}
                  StartIcon={
                    <ClaimIcon
                      className="relative z-10 w-4 h-4 col-span-1"
                      // fill={
                      //   claimRewardLoading || allRewardsAreZeroOrFalsy
                      //     ? 'white'
                      //     : 'none'
                      // }
                      stroke={
                        claimRewardLoading || allRewardsAreZeroOrFalsy
                          ? 'white'
                          : '#7AB6D1'
                      }
                    />
                  }
                  
                  classNames={`!w-[170px] rounded-lg font-normal !text-sm !py-[10px] px-5 min-w-max place-item-end  bg-[#DCF1F8] font-semibold text-primary
                               ${claimRewardLoading || allRewardsAreZeroOrFalsy ? '!bg-[#EFEFEF] cursor-not-allowed !text-[#ADADAD]' : ''} `}
                  onClick={() => {
                    claimCloudkRewardFunc().then((response) => {
                      if (response) {
                        getCloudKTransactionHistoryV2(transactionHistoryType ?? tabs[0].key, transactionHistoryFilters ?? {
                          page: 1,
                          limit: 10,
                          licenseName: '',
                          sort: {},
                        })
                      }
                    }).catch((error) => {
                      return error

                    });
                  }}
                />
              </Tooltips>
            </div>
          </div>
        </div>
      )}
    </CardLayout>
  );
}

export default RewardGenerated
