import { useCallback, useEffect, useState } from 'react';

import { useSuperNodeActions } from '../../store/superNode/superNodeActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSuperNodeSlice,
  setSuperNodeSlice,
} from '../../store/superNode/superNodeSlice';
import SuperNodeTable from './_components/SuperNodeTable';
import {
  capitalizeFirstLetter,
  formatAlphaNumericDate,
  toFixedWithoutRoundingOff,
} from '../../utils/utils';
import moment from 'moment';
import GlobalSelect from '../../components/Global/GlobalSelect';
import useQueryParams from '../../hooks/useQueryParams';
import Tabs from '../../components/Tabs';
import { useNavigate } from 'react-router-dom';
import { profileImg } from '../../images';
import { set } from 'date-fns';

const tabs = [
  { label: 'Total Rewards', key: 'total-reward' },
  { label: 'Base Referral', key: 'base-referral' },
  { label: 'Builder Generational', key: 'builder-generational' },
  // { label: 'Builder Referral', key: 'builder-referral' },
  { label: 'Matching Bonus', key: 'matching-bonus' },
];

function SuperNodeReports() {
  const navigate = useNavigate();

  const { getRewardsTableData, getMyReport, getTotalBonus } =
    useSuperNodeActions();
  const {
    type: typeParam,
    level: levelParam,
    fromDate: fromDateParam,
    toDate: toDateParam,
  } = useQueryParams(['type', 'level', 'fromDate', 'toDate']);

  const {
    superNodeSuperNodeRewardsLoading,
    superNodeSuperNodeRewards,
    totalBonus,
    getTotalBonusLoading,
  } = useSelector(selectSuperNodeSlice);

  const dispatch = useDispatch();

  const superNodesRewardsType: { name: string; value: number }[] = Array.from(
    { length: 30 },
    (_, i) => ({
      name: `level-${i + 1}`,
      value: i + 1,
    })
  );

  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedButton, setSelectedButton] = useState<string>(
    typeParam || 'total-reward'
  );
  const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);
  const [selectedReward, setSelectedReward] = useState<any>(null);
  const [resetDisable, setResetDisable] = useState(true);
  const [dataChanged, setDateChanged] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState<any>(
    Number(levelParam) || null
  );
  const [resetFilter, setResetFilter] = useState(false);
  const [filters, setFilters] = useState<any>({
    limit: 10,
    page: 1,
    type: selectedButton,
    fromDate: fromDateParam || null,
    toDate: toDateParam || null,
    query: null,
    totalPages: 0,
    totalCount: 0,
    level: selectedLevel,
  });

  const [bonusFilters, setBonusFilters] = useState<any>({
    type: typeParam || 'total-reward',
    fromDate: fromDateParam || null,
    toDate: toDateParam || null,
    level: selectedLevel,
  });

  const rows =
    superNodeSuperNodeRewards?.list?.map((rewards: any) => ({
      fromuser: (
        <div className="flex items-center gap-2">
          <img
            src={rewards?.fromUser?.profilePicture || profileImg}
            alt="user"
            className="w-12 h-12 rounded-full"
          />
          <div className="flex flex-col justify-between  min-w-0">
            <span className="text-black flex-1 truncate block min-w-0">
              {rewards?.fromUser?.username}
            </span>
            <span className="text-black">
              BID: {rewards?.fromUser?.blockchainId}
            </span>
            <span className="text-black">
              NodeK Reward: $
              {toFixedWithoutRoundingOff(rewards?.rewardData?.amount || 0, 4)}
            </span>
          </div>
        </div>
      ),
      rewardLevel: (
        <p className="!text-center">{rewards?.rewardData?.currentLevel}</p>
      ),
      treeLevel: (
        <p className="!text-center">
          {rewards?.rewardData?.actualLevel ?? '-'}
        </p>
      ),
      type: rewards?.type,
      rewardData: toFixedWithoutRoundingOff(rewards?.amount || 0, 4),
      RemainingGASK: toFixedWithoutRoundingOff(rewards?.gaskRemaining || 0, 2),
      date: formatAlphaNumericDate(rewards.createdAt),
      action: (
        <button
          onClick={() => {
            setSelectedReward(rewards);
          }}
          className="bg-primary text-white px-4 py-1.5 rounded-md text-xs"
        >
          REPORT
        </button>
      ),
    })) || [];
  const handlePageChange = (page: number) => {
    const updatedFilters = { ...filters, page };
    setFilters(updatedFilters);
    fetchData(updatedFilters);
  };

  const filterEmptyCheck = () =>
    !filters.query && !filters.fromDate && !filters.toDate && !filters?.level;

  const fetchData = async (filters: any) => {
    setResetFilter(false);
    let rewardsResponse;
    rewardsResponse = await getRewardsTableData({
      page: filters?.page,
      limit: 10,
      query: filters?.query,
      fromDate: filters?.fromDate,
      toDate: filters?.toDate,
      type: filters?.type,
      level: filters?.level,
    });

    if (
      rewardsResponse?.data?.data &&
      rewardsResponse?.data?.data?.list?.length > 0
      // &&
      // resetDisable
    ) {
      setResetDisable(false);
    }
  };

  const handleSearch = (query: string) => {
    setSearchIsLoading(true);
    // Simulate search
    const updatedFilters = { ...filters, query };
    setSearchValue(query ?? null);
    setFilters(updatedFilters);
    setTimeout(() => {
      fetchData(updatedFilters);
      setSearchIsLoading(false);
    }, 1000);
  };

  const handleDateDate = (fromDate: string, toDate: string) => {
    const from = moment(fromDate).format('YYYY-MM-DD');
    const to = moment(toDate).format('YYYY-MM-DD');

    setFilters((prev: any) => ({
      ...prev,
      fromDate: from,
      toDate: to,
      page: 1,
    }));

    setBonusFilters((prev: any) => ({
      ...prev,
      fromDate: from,
      toDate: to,
      page: 1,
    }));

    const updatedFilters = {
      ...filters,
      fromDate: from,
      toDate: to,
      page: 1,
    };
    const updatedBonusFilters = {
      ...bonusFilters,
      fromDate: from,
      toDate: to,
    };
    fetchData(updatedFilters);
    getTotalBonus(updatedBonusFilters);
  };

  const handleReset = () => {
    setSearchValue('');
    const newFilter = {
      limit: 10,
      page: 1,
      type: filters.type,
      fromDate: null,
      toDate: null,
      query: null,
      totalPages: 0,
      totalCount: 0,
      level: null,
    };

    const updatedBonusFilters = {
      type: bonusFilters.type,
      fromDate: null,
      toDate: null,
      level: null,
    };

    setFilters(newFilter);
    setBonusFilters(updatedBonusFilters);
    fetchData(newFilter);
    getTotalBonus(updatedBonusFilters);
    setSelectedLevel('');
    setResetFilter(true);
    setDateChanged(false);
  };

  const getRewardTable = (item: any) => [
    item?.fromUser?.blockchainId ?? '-',
    item?.fromUser?.username ?? '-',
    item?.rewardData?.amount
      ? `$ ${toFixedWithoutRoundingOff(item?.rewardData?.amount || 0, 4)}`
      : '-',
    item?.rewardData?.currentLevel ?? '-',
    item?.type ?? '-',
    item?.rewardData?.actualLevel ?? '-',
    item?.amount
      ? `${toFixedWithoutRoundingOff(item?.amount || 0, 4)} LYK-W`
      : '-',
    item?.gaskRemaining
      ? toFixedWithoutRoundingOff(item?.gaskRemaining || 0, 2)
      : '-',
    item?.createdAt
      ? `${formatAlphaNumericDate(item?.createdAt).replace(',', ' - ')}`
      : '-',
  ];

  const handleDownloadCSV = async () => {
    let data: any[] = []; // Ensure the data array is initialized

    const headers = [
      'BID',
      'From User',
      'NodeK Reward',
      'Reward Level',
      'Type',
      'Tree Level',
      'Reward Amount',
      'Remaining GASK',
      'Date',
    ];
    let response = await getRewardsTableData({
      page: filters?.page,
      limit: superNodeSuperNodeRewards?.totalCount
        ? superNodeSuperNodeRewards?.totalCount
        : 10,
      query: filters?.query,
      fromDate: filters?.fromDate,
      toDate: filters?.toDate,
      type: filters?.type,
      level: filters?.level,
      storeRedux: false,
    });

    let rewardsData = response?.data?.data?.list;

    if (selectedButton === 'total-reward') {
      data.push([
        `TotalReward: ${totalBonus?.total?.totalReward || 0} LYK-W`,
        `BaseRefferal: ${totalBonus?.total?.baseRefferal || 0} LYK-W`,
        `BuilderGenerational: ${totalBonus?.total?.builderGenerational || 0} LYK-W`,
        `MatchingBonus: ${totalBonus?.total?.matchingBonus || 0} LYK-W`,
        `FromDate: ${formatAlphaNumericDate(totalBonus?.fromDate).replace(',', ' - ') || 'N/A'}`,
        `ToDate: ${formatAlphaNumericDate(totalBonus?.toDate).replace(',', ' - ') || 'N/A'}`,
      ]);
      data.push(headers);
      rewardsData.forEach((item: any) => {
        data.push(getRewardTable(item));
      });
    } else if (selectedButton === 'base-referral') {
      data.push([`TotalBaseRefferal: ${totalBonus?.total || 0} LYK-W`]);
      data.push(headers);
      rewardsData.forEach((item: any) => {
        data.push(getRewardTable(item));
      });
    } else if (selectedButton === 'builder-generational') {
      data.push([`BuilderGenerational: ${totalBonus?.total || 0} LYK-W`]);
      data.push(headers);
      rewardsData.forEach((item: any) => {
        data.push(getRewardTable(item));
      });
    } else if (selectedButton === 'matching-bonus') {
      data.push([`MatchingBonus: ${totalBonus?.total || 0} LYK-W`]);
      data.push(headers);
      rewardsData.forEach((item: any) => {
        data.push(getRewardTable(item));
      });
    }
    return Promise.resolve(data);
  };

  const handleButtonClick = async (buttonText: any) => {
    dispatch(
      setSuperNodeSlice({
        getTotalBonusLoading: true,
      })
    );
    setSelectedButton(buttonText);

    setSearchValue('');

    const newFilter = {
      limit: 10,
      page: 1,
      type: buttonText,
      fromDate: null,
      toDate: null,
      query: null,
      totalPages: 0,
      totalCount: 0,
      level: null,
    };

    const newBonusFilter = {
      type: buttonText || 'total-reward',
      fromDate: null,
      toDate: null,
      level: null,
    };

    setFilters(newFilter);
    setBonusFilters(newBonusFilter);

    await fetchData(newFilter);
    await getTotalBonus(newBonusFilter);

    setSelectedLevel('');
    setResetFilter(true);
    setDateChanged(false);
  };

  const handleTotalCardData = async () => {
    await getMyReport({
      type: selectedButton === '' ? 'total-reward' : selectedButton,
    });
  };
  useEffect(() => {
    if (!superNodeSuperNodeRewardsLoading) {
      fetchData(filters);
    }
    if (!getTotalBonusLoading) {
      getTotalBonus(bonusFilters);
    }
  }, []);

  useEffect(() => {
    handleTotalCardData();
  }, [selectedButton]);

  return (
    <div className="relative flex flex-col">
      <div className="flex items-start justify-between">
        <div className="flex flex-wrap items-center">
          <button
            onClick={() => navigate('/community/super-node-2')}
            className="p-0 text-xl bg-transparent cursor-pointer font-baumans"
          >
            Super Node {'>'} &nbsp;
          </button>
          <span className="text-2xl font-baumans">
            {selectedButton
              ? capitalizeFirstLetter(
                  selectedButton
                    .replace(/-/g, ' ')
                    .replace(/\b\w/g, (char) => char.toUpperCase()) ?? ''
                )
              : 'Total Rewards'}
          </span>
        </div>
      </div>
      <div>
        <Tabs
          selectedButton={selectedButton}
          handleButtonClick={handleButtonClick}
          tabs={tabs}
          parentClassName="!px-0 !text-nowrap !text-xs"
          className="sm:!py-2"
        />
        <SuperNodeTable
          rows={rows}
          isLoading={superNodeSuperNodeRewardsLoading}
          handlePageChange={handlePageChange}
          page={filters?.page}
          totalPages={superNodeSuperNodeRewards?.totalPages || 1}
          tableTitle="Super Node Table"
          handleSearch={handleSearch}
          searchValue={searchValue}
          handleReset={handleReset}
          filterEmptyCheck={filterEmptyCheck}
          searchIsLoading={searchIsLoading}
          filterSearchBaseClassName="base-search-class"
          handleDateDate={handleDateDate}
          // fromDate={filters?.fromDate}
          // toDate={filters?.toDate}
          resetDateFilter={resetFilter}
          dateArrowAllow={true}
          selectedReward={selectedReward}
          selectedButton={selectedButton}
          initialStartDate={filters?.fromDate}
          initialEndDate={filters?.toDate}
          tableDropdowns={
            <>
              <GlobalSelect
                name="level"
                placeholder="Level"
                isError={false}
                value={selectedLevel}
                getSelectedValue={(level: string) => {
                  setFilters((prev: any) => {
                    const updatedFilter = { ...prev, page: 1, level };
                    setSelectedLevel(level);
                    fetchData(updatedFilter);
                    return updatedFilter;
                  });

                  setBonusFilters((prevBonusFilters: any) => {
                    const updatedBonusFilters = { ...prevBonusFilters, level };

                    getTotalBonus(updatedBonusFilters);

                    return updatedBonusFilters;
                  });

                  setSelectedLevel(level);
                }}
                options={superNodesRewardsType}
                className={'!rounded-md !min-w-24 !w-auto'}
                classNameSelect="!border-defaultBorder !rounded-md py-2.5 px-5 mr-6 "
                optionsWidth="w-max"
                // selectdisable={resetDisable}
              />
            </>
          }
          //             fromDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''} from-date-class"
          // `}
          //             toDateClassName={`${resetDisable ? 'cursor-not-allowed' : ''} to-date-class`}
          //             filterSearchClassName={`${resetDisable ? 'cursor-not-allowed' : ''} input-search-class`}
          // searchdisable={resetDisable}
          // fromDateDisable={resetDisable}
          // toDateDisable={resetDisable}
          setDateChanged={setDateChanged}
          DownloadCSV={handleDownloadCSV}
          type={selectedButton}
          csvColumns={tabs}
        />
      </div>
    </div>
  );
}
export default SuperNodeReports;
