import GlobalHeading from '../../components/Global/GlobalHeading';
import { ReactElement } from 'react';
import {
  ActiveVoucher,
  NoVouchers,
  RedeemedVoucher,
} from '../../images/svgs';
import GlobalSkeleton from '../../components/Global/GlobalSkeleton';
import useVouchers from './hooks/useVouchers';
import { IStatusTabs } from './types/vouchers.type';
import VoucherCardNew from './components/VoucherCardNew';
import ModalWrapper from './components/ModalWrapper';

const Vouchers = (): ReactElement => {
  const {
    vouchers,
    userVouchersLoading,
    vouchersData,
    activeTab,
    colors,
    statusTabs,
    handleTimeTabClick,
  } = useVouchers();
  return (
    <div className="">
      <GlobalHeading
        heading={`My Vouchers`}
        className="flex-1 !text-defaultBlack"
      />
      {vouchersData.length > 0 && (
        <div className="flex flex-col mb-2 lg:flex-row lg:gap-4">
          <div className="flex flex-col flex-wrap gap-2 my-4 screen510:flex-row">
            {statusTabs.map((status: IStatusTabs): ReactElement => {
              return (
                <button
                  key={status.key}
                  onClick={(): void => handleTimeTabClick(status)}
                  className={`flex gap-2 px-8 py-1.5 font-normal text-base rounded-lg min-w-[250px]
                      hover:text-primary border whitespace-nowrap ${activeTab === status.key ? 'border-primary text-primary' : 'text-secondaryBlack border-tabBorder'} bg-white`}
                // style={{
                //   transformStyle: 'preserve-3d',
                // }}
                >
                  {/* {activeTab === status.key && (
                  <motion.span
                  layoutId="bubble"
                  className="absolute inset-0 bg-inherit rounded-lg !border-primary"
                  transition={{
                    type: 'spring',
                    bounce: 0.2,
                    duration: 0.6,
                    }}
                  />
                )} */}
                  {status.key === 'active' ? (
                    <ActiveVoucher
                      className={`${status.key === activeTab ? 'text-primary' : 'text-secondaryBlack'} z-10`}
                    />
                  ) : (
                    <RedeemedVoucher
                      className={`${status.key === activeTab ? 'text-primary' : 'text-secondaryBlack'} z-10 ${status.key === 'expired' && 'rotate-180'}`}
                    />
                  )}
                  <span
                    className={`relative block ${activeTab === status.key ? 'text-primary' : 'text-secondaryBlack'}`}
                  >
                    {status.label}
                  </span>
                </button>
              );
            })}
          </div>
          <ModalWrapper />
        </div>
      )}
      {userVouchersLoading && vouchersData.length === 0 ? (
        <>
          <div className="grid grid-cols-1 gap-5 mt-10 screen890:grid-cols-2 screen1200:grid-cols-3">
            {Array(3)
              .fill('')
              .map(
                (): ReactElement => (
                  <GlobalSkeleton
                    animationValue="wave"
                    variant="rectangular"
                    width={280}
                    className="relative w-full bg-white border rounded-lg shadow-lg"
                    height={400}
                  />
                )
              )}
          </div>
        </>
      ) : (
        <>
          {vouchers.length > 0 ? (
            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
              {vouchers.map(
                (voucher: any, index: number): ReactElement => (
                  <VoucherCardNew
                    key={voucher.internalVoucherCode}
                    voucher={voucher}
                    redeemBtn={activeTab == 'active' ? true : false}
                    color={voucher.type === 'plus' ? colors.plus : voucher.type === 'pro' ? colors.pro : voucher.type === 'expert' ? colors.expert : ''}
                  />
                )
              )}
            </div>
          ) : (
            <div className="flex flex-1 justify-center h-[60vh] items-center w-full rounded-3xl">
              <div className="flex flex-col items-center justify-center h-auto">
                <NoVouchers />
                <span className="font-normal text-secondaryBlack xxs:text-sm sm:text-md">
                  There is currently no Voucher Available
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Vouchers;
