import moment from 'moment';
import {
  capitalizeFirstLetter,
  formatStatusToDispaly,
  lowercaseFirstLetter,
  toFixedFormat,
} from '../../../../../utils/utils';
import { mapStatusColor } from '../../../../../utils/statusColors';
import Tooltips from '../../../../../components/Tooltips';
import { InfoIcon } from '../../../../../images/svgs';

export const mapColumns: any = {
  Licenses: [
    {
      label: 'License Name',
      key: 'licenseName',
      mapContent: (value: any) => <div className="font-medium">{value}</div>,
    },
    {
      label: 'Purchase Date',
      key: 'purchaseDate',
      mapContent: (value: any) => moment(value).format('lll'),
      sortable: true,
    },
    {
      label: 'Amount Spent',
      key: 'amount',
      sortable: true,
      mapContent: (value: any) => `$${value}`,

    },
    {
      label: 'Validity',
      sortable: true,
      key: 'licenseValidity',
      mapContent: (value: any) => moment(value).format('lll'),
    },
    {
      label: 'Status',
      key: 'status',
      sortable: true,
      mapContent: (value: any) => (
        <span
          className="font-medium text-xs py-1 px-3 rounded-lg"
          style={mapStatusColor(value)}
        >
          {formatStatusToDispaly(value)}
        </span>
      ),
    },
  ],
  Stake_Events: [
    { label: 'License Name', key: 'licenseName' },
    {
      label: 'Stake Amount', key: 'stakedAmount', sortable: true,
      mapContent: (transaction: any, valueKey: any) => {
        const value = `${toFixedFormat(Number(transaction?.[valueKey] || 0), 3)}  ${lowercaseFirstLetter(`${transaction?.['rewardToken'] ?? ''}`)}`;

        if (transaction?.isAutoCompound == true) {
          return <Tooltips
            placement="top"
            containerClass='!justify-start'
            content={
              <div className="p-3 text-xs font-normal bg-white rounded-lg shadow-md">Auto Linked</div>
            }
          >
            <span className="!font-semibold">{value}</span>
          </Tooltips>
        }
        return value;
      }
    },
    {
      label: 'Stake Period',
      key: 'stakePeriod',
      mapContent: (value: any) => capitalizeFirstLetter(value),
    },
    {
      label: 'Collateral', key: 'collateral', mapContent: (value: any) => {
        let numericValue = parseFloat(value.replace('$', ''));
        return ("$" + toFixedFormat(Number(numericValue || 0), 2))
      }
    },
    {
      label: 'Date',
      key: 'createdAt',
      mapContent: (value: any) => moment(value).format('lll'),
      sortable: true,
    },
    {
      label: 'Expiry Date',
      key: 'expiryDate',
      mapContent: (value: any) => moment(value).format('lll'),
      sortable: true,
    },
    {
      label: 'Status',
      key: 'status',
      sortable: true,
      mapContent: (value: any) => (
        <span
          className="font-medium text-xs py-1 px-3 rounded-lg "
          style={mapStatusColor(value)}
        >
          {formatStatusToDispaly(value)}
        </span>
      ),
    },
  ],
  Rewards: [
    {
      label: 'From', key: 'from',
      // mapValueKey: (dataObject:any) => dataObject?.rewardFrom == 'license'? 'licenseName' : 'minterName' 
    },
    { label: 'Type', key: 'rewardFrom', mapContent: (value: any) => capitalizeFirstLetter(value) },
    {
      label: 'Reward Type',
      key: 'rewardToken',
      mapContent: (value: any) => {
        if (value == "mlyk") {
          return "mLYK"
        } else {
          return value?.toUpperCase()
        }
      },
    },
    {
      label: 'Amount',
      key: 'amount',
      mapContent: (value: any) => toFixedFormat(Number(value || 0), 3)
    },

    {
      label: 'Status',
      key: 'status',
      sortable: true,
      mapContent: (data: any) => (
        <span
          className="font-medium text-xs py-1 px-3 rounded-lg"
          style={mapStatusColor(data)}
        >
          {formatStatusToDispaly(data)}
        </span>
      ),
    },
    {
      label: 'Date',
      key: 'actionDate',
      sortable: true,
      mapContent: (value: any) => moment(value).format('lll'),
    },
  ],
  Hardware_Connections: [
    { label: 'Hardware Name', key: 'minterName' },
    { label: 'Linked License', key: 'licenseName' },
    {
      label: 'Action Type',
      key: 'actionType',
      mapContent: (value: any) => formatStatusToDispaly(value),
    },
    {
      label: 'Status',
      key: 'status',
      sortable: true,
      mapContent: (value: any) => (
        <span
          className="font-medium text-xs py-1 px-3 rounded-lg"
          style={mapStatusColor(value)}
        >
          {formatStatusToDispaly(value)}
        </span>
      ),
    },
    {
      label: 'Date',
      key: 'actionDate',
      sortable: true,
      mapContent: (value: any) => moment(value).format('lll'),
    },
  ],
  Collaterals: [
    { label: 'License Name', key: 'licenseName' },
    {
      label: 'Collateral Amount',
      key: 'amount',
      sortable: true,
      mapContent: (value: any) => `${toFixedFormat(Number(value || 0), 3)}mLYK`

    },
    {
      label: 'Action Type',
      key: 'actionType',
      mapContent: (value: any) => (
        <span
          className="font-medium text-xs py-1 px-3 rounded-lg"
          style={mapStatusColor(value)}
        >
          {formatStatusToDispaly(value)}
        </span>
      ),
    },
    {
      label: 'Status',
      key: 'status',
      sortable: true,
      mapContent: (value: any) => (
        <span
          className="font-medium text-xs py-1 px-3 rounded-lg"
          style={mapStatusColor(value)}
        >
          {formatStatusToDispaly(value)}
        </span>
      ),
    },
    {
      label: 'Date',
      key: 'createdAt',
      sortable: true,

      mapContent: (value: any) => moment(value).format('lll'),
    },
  ],
  Period_Upgrades: [
    { label: 'License Name', key: 'licenseName' },
    {
      label: 'Previous Staking Period',
      key: 'previousStakingPeriod',
      mapContent: (value: any) => capitalizeFirstLetter(value),
    },
    {
      label: 'New Staking Period',
      key: 'newStakingPeriod',
      mapContent: (value: any) => capitalizeFirstLetter(value),
    },
    {
      label: 'Status',
      key: 'status',
      sortable: true,
      mapContent: (value: any) => (
        <span
          className="font-medium text-xs py-1 px-3 rounded-lg"
          style={mapStatusColor(value)}
        >
          {formatStatusToDispaly(value)}
        </span>
      ),
    },
    {
      label: 'Stake Expiry Date',
      key: 'expiryDate',
      sortable: true,
      mapContent: (value: any) => moment(value).format('lll'),
    },
    {
      label: 'Date',
      key: 'upgradeDate',
      sortable: true,
      mapContent: (value: any) => moment(value).format('lll'),
    },
  ],
};

export const hardwareMapColumns: any = {

  Rewards: [
    { label: 'Hardware Name', key: 'minterName' },
    { label: 'License Name', key: 'licenseName' },
    {
      label: 'Reward Type',
      key: 'rewardToken',
      mapContent: (data: any) => data?.toUpperCase(),
    },
    {
      label: 'Amount',
      key: 'amount',
      mapContent: (value: any) => toFixedFormat(Number(value || 0), 3)
    },
    {
      label: 'Status',
      key: 'status',
      sortable: true,
      mapContent: (data: any) => (
        <span
          className="font-medium text-xs py-1 px-3 rounded-lg"
          style={mapStatusColor(data)}
        >
          {formatStatusToDispaly(data)}
        </span>
      ),
    },
    {
      label: 'Date',
      key: 'actionDate',
      sortable: true,
      mapContent: (data: any) => moment(data).format('lll'),
    },
  ],
  Hardware_Connections: [
    { label: 'Hardware Name', key: 'minterName' },
    { label: 'Linked License', key: 'licenseName' },
    { label: 'Nft Weightage', key: 'licenseNftWeightage' },

    // {
    //   label: 'Action Type',
    //   key: 'actionType',
    //   mapContent: (data: any) => formatStatusToDispaly(data),
    // },
    {
      label: 'Status',
      key: 'status',
      sortable: true,
      mapContent: (row: any, transaction: any) => {
        const isLinked = row === "linked";
        const isManualDelink = transaction.isManualDelink && row === "de-linked";
        const autoDelinked = !transaction.isManualDelink && row === "de-linked";

        const renderTooltipContent = (message: string) => (
          <div className="p-3 bg-white shadow-md rounded-lg text-xs">
            <div className="font-normal">{message}</div>
          </div>
        );

        const renderDeLinkedStatus = (message: string, showTooltip: boolean) => (
          <div className="flex justify-start items-start">
            <span
              className="py-1 px-2 bg-[#FFA2A245] text-[#A20000] rounded-md text-xs font-medium flex items-center justify-center w-24" // Fixed width
            >
              De-Linked
              {showTooltip && (
                <Tooltips
                  containerClass="ml-2 flex items-center justify-center"
                  content={renderTooltipContent(message)}
                  placement="top-end"
                >
                  <InfoIcon className="w-4 h-4 cursor-pointer" />
                </Tooltips>
              )}
            </span>
          </div>
        );

        return (
          <span>
            {isLinked && (
              <span
                className="font-medium text-xs py-1 px-2 rounded-lg !w-24 flex justify-center"
                style={mapStatusColor(row)}
              >
                {formatStatusToDispaly(row)}
              </span>
            )}

            {autoDelinked && renderDeLinkedStatus("Auto-delinked due to Expired License / Staking Period.", true)}

            {isManualDelink && renderDeLinkedStatus("Manually de-linked by an administrator", false)}
          </span>
        );
      },
    },
    {
      label: 'Date',
      key: 'actionDate',
      sortable: true,
      mapContent: (data: any) => moment(data).format('lll'),
    },
  ],
  Hardware_Activities: [
    { label: 'Hardware Name', key: 'minterName' },
    { label: 'Serial Number', key: 'serialNumber' },
    {
      label: 'Type',
      key: 'hardwareType',
      mapContent: (data: any) => data.toUpperCase(),
    },
    {
      label: 'Status',
      key: 'hardwareStatus',
      sortable: true,
      mapContent: (data: any) => (
        <span
          className="font-medium text-xs py-1 px-3 rounded-lg"
          style={mapStatusColor(data)}
        >
          {formatStatusToDispaly(data)}
        </span>
      ),
    },
    {
      label: 'Date',
      key: 'createdAt',
      sortable: true,
      mapContent: (data: any) => moment(data).format('lll'),
    },
  ]
};

export const singleHardwareMapColumns: any = {

  Rewards: [
    { label: 'License Name', key: 'licenseName' },
    {
      label: 'Reward Type',
      key: 'rewardToken',
      mapContent: (data: any) => data?.toUpperCase(),
    },
    {
      label: 'Amount',
      key: 'amount',
      mapContent: (value: any) => toFixedFormat(value, 4)
    },

    {
      label: 'Status',
      key: 'status',
      sortable: true,
      mapContent: (data: any) => (
        <span
          className="font-medium text-xs py-1 px-3 rounded-lg"
          style={mapStatusColor(data)}
        >
          {formatStatusToDispaly(data)}
        </span>
      ),
    },
    {
      label: 'Date',
      key: 'actionDate',
      sortable: true,
      mapContent: (data: any) => moment(data).format('lll'),
    },
  ],
  Hardware_Connections: [
    { label: 'Hardware Name', key: 'minterName' },
    { label: 'Linked License', key: 'licenseName' },
    { label: 'Nft weightage', key: 'licenseNftWeightage' },
    {
      label: 'Status',
      key: 'status',
      sortable: true,
      mapContent: (row: any, transaction: any) => {
        const isLinked = row === "linked";
        const isManualDelink = transaction.isManualDelink && row === "de-linked";
        const autoDelinked = !transaction.isManualDelink && row === "de-linked";

        const renderTooltipContent = (message: string) => (
          <div className="p-3 bg-white shadow-md rounded-lg text-xs">
            <div className="font-normal">{message}</div>
          </div>
        );

        const renderDeLinkedStatus = (message: string, showTooltip: boolean) => (
          <div className="flex justify-start items-start">
            <span
              className="py-1 px-2 bg-[#FFA2A245] text-[#A20000] rounded-md text-xs font-medium flex items-center justify-center w-24" // Fixed width
            >
              De-Linked
              {showTooltip && (
                <Tooltips
                  containerClass="ml-2 flex items-center justify-center"
                  content={renderTooltipContent(message)}
                  placement="top-end"
                >
                  <InfoIcon className="w-4 h-4 cursor-pointer" />
                </Tooltips>
              )}
            </span>
          </div>
        );

        return (
          <span>
            {isLinked && (
              <span
                className="font-medium text-xs py-1 px-2 rounded-lg !w-24 flex justify-center"
                style={mapStatusColor(row)}
              >
                {formatStatusToDispaly(row)}
              </span>
            )}

            {autoDelinked && renderDeLinkedStatus("Auto-delinked due to Expired License / Staking Period.", true)}

            {isManualDelink && renderDeLinkedStatus("Manually de-linked by an administrator", false)}
          </span>
        );
      },
    },
    {
      label: 'Date',
      key: 'actionDate',
      sortable: true,
      mapContent: (data: any) => moment(data).format('lll'),
    },
  ],
  // Hardware_Activities :[
  //   { label: 'Hardware Name', key: 'minterName' },
  //   {
  //     label: 'Action Type',
  //     key: 'actionType',
  //     mapContent: (data: any) => formatStatusToDispaly(data),
  //   },
  //   {
  //     label: 'Status',
  //     key: 'status',
  //     sortable: true,
  //     mapContent: (data: any) => (
  //       <span
  //         className="font-medium text-xs py-1 px-3 rounded-lg"
  //         style={mapStatusColor(data)}
  //       >
  //         {formatStatusToDispaly(data)}
  //       </span>
  //     ),
  //   },
  //   {
  //     label: 'Date',
  //     key: 'actionDate',
  //     sortable: true,
  //     mapContent: (data: any) => moment(data).format('lll'),
  //   },
  // ]
};
