import { memo } from 'react'
import GlobalModal from '../../../components/Global/GlobalModal';
import GlobalButton from '../../../components/Global/GlobalButton';
import { voucherClickIcon, voucherIcon, voucherModalBg, voucherSoftwareIcon } from '../../../images';
interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const HowToRedeemModal = memo(({ isOpen, onClose }: Props) => {
  return (
    <GlobalModal
      isOpen={isOpen}
      onClose={onClose}
      size='xl'
    >
      <img src={voucherModalBg} className='absolute right-0 top-0 -z-10' />
      <div className='flex flex-col gap-5 mx-2 relative'>
        <div className='font-medium text-xl text-defaultBlack'>How to Redeem</div>
        <div className='flex flex-col gap-2'>
          <div className='font-normal text-base text-primaryGrey'>1. Access Quantwise. </div>
          <div className='font-normal text-base text-primaryGrey'><span>2. Redeem your voucher code</span><img src={voucherIcon} className='inline-block ml-1' /></div>
          <div className='font-normal text-base text-primaryGrey'>3. Navigate to the Software Section on Quantwise, copy the voucher code, and click "Connect Now"<img src={voucherClickIcon} className='ml-1 inline-block' /></div>
          <div className='font-normal text-base text-primaryGrey'><span>4. Create your Unicapital Account.</span></div>
          <div className='font-normal text-base text-primaryGrey'>5. Paste the voucher code to unlock your selected trading service or software<img src={voucherSoftwareIcon} className='ml-1 inline-block' /> </div>
          <div className='font-normal text-base text-primaryGrey'>6. Follow the on-screen instructions to begin investing. </div>
        </div>
      </div>
      <div className='flex justify-center mb-3'>
        <GlobalButton
          classNames="bg-primary !rounded-full text-white md:!text-lg font-medium w-32"
          text="Done"
          bigFont={true}
          onClick={onClose}
        />
      </div>
    </GlobalModal>
  )
})

export default HowToRedeemModal